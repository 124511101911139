<template>
  <div>
    <div class="title">选择对话</div>
      <el-select v-model="session_id" placeholder="请选择一个对话" style="width: 100%;margin-bottom: 10px;">
        <el-option
          v-for="item in session_options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    <el-button class="btn" type="primary" round @click="select_session">选择对话</el-button>
    <div class="title" style="margin-top: 20px;">自定义Prompt指令</div>
    <el-input
      type="textarea"
      :rows="8"
      placeholder="请输入SystemPrompt"
      v-model="system_prompt"
      class="textarea">
    </el-input>
    <el-button class="btn" type="primary" round @click="save_prompt">保存</el-button>
    <div style="margin-top: 20px;">
      <div class="title">聊天记录</div>
      <el-input
        type="textarea"
        :rows="8"
        v-model="chat_history"
        class="textarea">
      </el-input>
    </div>
    <el-button class="btn" type="primary" round @click="reset_system_prompt">清空聊天记录</el-button>
    <el-form style="margin-top:20px;" label-width="100px" :inline="true" :model="detail_params" class="demo-form-inline">
      <el-form-item label="platform">
        <el-select style="width: 30vh;" v-model="detail_params.platform" placeholder="请选择平台" @change="changePlatform">
          <el-option
            v-for="item in platform_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="model" v-show="detail_params.platform=='MINIMAX'">
        <el-select style="width: 30vh;" v-model="detail_params.model" placeholder="请选择模型">
          <el-option
            v-for="item in minimax_model_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="model" v-show="detail_params.platform=='VOLCENGINE'">
        <el-select style="width: 30vh;" v-model="detail_params.model" placeholder="请选择模型">
          <el-option
            v-for="item in volcengine_model_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="model" v-show="detail_params.platform=='ZHIPU'">
        <el-select style="width: 30vh;" v-model="detail_params.model" placeholder="请选择模型">
          <el-option
            v-for="item in zhipu_model_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="temperature">
        <el-input style="width: 30vh;"  v-model="detail_params.temperature" placeholder="请输入temperature"></el-input>
      </el-form-item>
      <el-form-item label="tts_engine">
        <el-select @change="change_tts_engine" style="width: 30vh;" v-model="detail_params.tts_engine" placeholder="请选择TTS引擎">
          <el-option
            v-for="item in tts_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="speaker_id" v-show="detail_params.tts_engine=='VITS'">
        <el-input style="width: 30vh;"  v-model="detail_params.speaker_id" placeholder="请输入speaker_id"></el-input>
      </el-form-item>
      <el-form-item label="speaker_id" v-show="detail_params.tts_engine=='BertVits'">
        <el-select style="width: 30vh;" v-model="detail_params.speaker_id" placeholder="请选择音色">
          <el-option
            v-for="item in speaker_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <el-form-item label="length_scale">
        <el-input style="width: 30vh;"  v-model="detail_params.length_scale" placeholder="请输入length_scale"></el-input>
      </el-form-item>
      <el-form-item label="language" v-show="detail_params.tts_engine=='BertVits'">
        <el-select style="width: 30vh;" v-model="detail_params.language" placeholder="请选择语言">
          <el-option
            v-for="item in language_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> 
      </el-form-item>
      <!-- <el-form-item label="style_text" v-show="detail_params.tts_engine=='BertVits'">
        <el-input style="width: 30vh;"  v-model="detail_params.style_text" placeholder="请输入style_text"></el-input>
      </el-form-item>
      <el-form-item label="style_weight" v-show="detail_params.tts_engine=='BertVits'">
        <el-input style="width: 30vh;"  v-model="detail_params.style_weight" placeholder="请输入style_weight"></el-input>
      </el-form-item> -->
    </el-form>
    <el-button class="btn" type="primary" round @click="update_detail_param">更新细节参数</el-button>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  data(){
    return{
      ip:"http://222.195.90.129",
      port:"8002",
      session_id:'',
      system_prompt:'',
      chat_history:'',
      detail_params:{
        platform:'',
        tts_engine:'',
        model:'',
        temperature:'',
        speaker_id:'',
        length_scale:'',
        language:'',
        // style_text:'',
        // style_weight:''
      },
      minimax_model_options:[
        {value:'abab6.5-chat',label:'abab6.5-chat'},
        {value:'abab6.5s-chat',label:'abab6.5s-chat'},
        {value:'abab6.5t-chat',label:'abab6.5t-chat'},
        {value:'abab6.5h-chat',label:'abab6.5g-chat'},
        {value:'abab5.5-chat',label:'abab5.5-chat'},
        {value:'abab5.5s-chat',label:'abab5.5s-chat'}
      ],
      volcengine_model_options:[
        {value:'doubao-4k-lite',label:'doubao-4k-lite'},
        {value:'doubao-32k-lite',label:'doubao-32k-lite'},
        {value:'doubao-32k-pro',label:'doubao-32k-pro'},
      ],
      zhipu_model_options:[
        {value:'glm-4-0520',label:'glm-4-0520'},
        {value:'glm-4',label:'glm-4'},
        {value:'glm-4-air',label:'glm-4-air'},
        {value:'glm-4-flash',label:'glm-4-flash'}
      ],
      speaker_options:[
        {value:0,label:'可莉'},
        {value:1,label:'钟离'},
        {value:2,label:'八重神子'},
        {value:3,label:'枫原万叶'},
        {value:4,label:'胡桃'}
      ],
      platform_options:[
        {
          value:'MINIMAX',
          label:'minimax'
        },
        {
          value:'VOLCENGINE',
          label:'火山引擎'
        },
        {
          value:'ZHIPU',
          label:"智谱"
        },
        {
          value:"MIXED",
          label:"MIXED"
        }
      ],
      tts_options:[
        {
          value:'VITS',
          label:'VITS'
        },
        {
          value: 'BertVits',
          label: 'BertVits'
        }
      ],
      language_options:[
        // {'value':'ZH',label:'中文'},
        // {'value':'EN',label:'英文'},
        {'value':'mix',label:'中英文'}
      ],
      session_options:[]
    }
  },
  methods:{
    reset_system_prompt(){
      axios.post(this.ip+':'+this.port+'/api/assistants/'+this.session_id +'/reset_msg').then(response=>{
        if(response.data.code==200){
          this.$notify({
            title: '成功',
            message: '清空聊天记录成功',
            type: 'success'
          });
          this.chat_history = ""
        }
      })
    },
    change_tts_engine(){
      this.detail_params.speaker_id = ''
    },
    changePlatform(){
      this.detail_params.model = ""
      this.detail_params.language = "ZH"
    },
    save_prompt(){
      const data = {'system_prompt':this.system_prompt}
      axios.put(this.ip+':'+this.port+'/api/assistants/'+this.session_id +'/system_prompt',data).then(response=>{
        if(response.data.code==200){
          this.$notify({
            title: '成功',
            message: '更新Prompt成功',
            type: 'success'
          });
        }
      })
    },
    select_session(){
      axios.get(this.ip+':'+this.port+'/api/assistants/'+this.session_id).then(response=>{
        let data = response.data.data
        let messages = JSON.parse(data.messages)
        this.chat_history = ""
        for(var i=1; i<messages.length; i++){
          this.chat_history += messages[i]['role'] + "：" + messages[i]['content'] +"\n"
        }
        this.system_prompt = data.system_prompt
        let user_info = JSON.parse(data['user_info'])
        this.detail_params.platform = user_info['llm_type']
        this.detail_params.tts_engine = user_info['tts_type']
        let llm_info = JSON.parse(data.llm_info)
        this.detail_params.model = llm_info.model
        this.detail_params.temperature = llm_info.temperature
        let tts_info = JSON.parse(data.tts_info)
        this.detail_params.speaker_id = tts_info.speaker_id
        this.detail_params.length_scale = tts_info.length_scale
        this.detail_params.language = tts_info.language
        // this.detail_params.style_text = tts_info.style_text
        // this.detail_params.style_weight = tts_info.style_weight
      }).catch(error => {console.error(error);});
    },
    update_detail_param(){
      const data = {
        "platform":this.detail_params.platform,
        "model":this.detail_params.model,
        "temperature":this.detail_params.temperature,
        "speaker_id":this.detail_params.speaker_id,
        "length_scale":this.detail_params.length_scale,
        "tts_engine":this.detail_params.tts_engine,
        "language":this.detail_params.language,
      }
      console.log(data)
      axios.put(this.ip+':'+this.port+'/api/assistants/'+this.session_id +'/deatil_params',data).then(response=>{
        if(response.data.code==200){
          this.$notify({
            title: '成功',
            message: '参数设置成功',
            type: 'success'
          });
        }
      })
    },
  },
  created(){
    axios.get(this.ip+':'+this.port+'/api/assistants').then(response=>{
      const data = response.data.data
      data.forEach(session => {
        this.session_options.push({label:session.name,value:session.id})
      });
      this.session_id = this.session_options[0]['value']
    })
    
  }
}
</script>

<style>
.title{
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
.textarea{
  font-size: 2vh;
  margin-bottom: 10px;
}
.btn{
  width: 100%;
}
</style>
